<template>
	<div id="appMain">
		<!-- <transition :name="transitionName">
			<router-view></router-view>
		</transition> -->
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive"></router-view>
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive"></router-view>
	</div>
</template>

<script>
export default {
	name: 'App',
	data () {
		return {
			transitionName: ''
		}
	},
	watch: {
		//使用watch 监听$router的变化
		$route(to, from) {
			//如果to索引大于from索引,判断为前进状态,反之则为后退状态
			if (to.meta.index > from.meta.index) {
				//设置动画名称
				this.transitionName = "slide-left";
			} else {
				this.transitionName = "slide-right";
			}
		},
	},
	mounted () {
		this.$store.dispatch('getSubjectData');
	}
}
</script>

<style lang="scss">
#appMain {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-webkit-overflow-scrolling: touch;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
    margin: 0 auto;
	min-height: 100vh;
	overflow: hidden;
}

.container {
	display: flex;
	width: 100vw;
	min-height: 100vh;
}

body {
	display: flex;
	width: 100%;
	min-height: 100vh;
	background-color: rgba($color: #FFB6B9, $alpha: .2);
	position: relative;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	&::before {
		position: absolute;
		content: ' ';
		bottom: 0;
		left: 0;
		right: 0;
		height: 42%;
		min-height: 300px;
		background: url('./assets/bg1.png') center no-repeat;
		background-size: cover;
		background-position: center 100%;
	}
}

.flex {
	display: flex;
}

.align-center {
	align-items: center;
}

.w-full {
	width: 100%;
}

.h-full {
	height: 100%;
}

:root {
	// 页面返回按钮
    --rap-back-btn-W: 50px;

	// 挑战页面按钮尺寸
	--rap-word-h: 50px;
	--rap-word-min-w: 70px;
	--rap-word-gap-x: 10px;
	--rap-word-gap-y: 10px;
	--rap-word-pad-x: 15px;

	// 首页布局
	--rap-menu-card-width: 80%;
	--rap-menu-direction: row;
	--rap-menu-width: 20%;
	--rap-menu-right: 3%;
	--rap-menu-bottom: 0;
	--rap-menu-h: 60px;
	--rap-menu-card-height: 100%;
	--rap-menu-columns: 4;

	// 单词听写
	--rap-word-statistics-m: 20px 0;
	--rap-progress-timer-mb: 10px;
	--rap-word-operate-m: 30px 0 40px 0;
	--rap-word-btn-w: 60px;
	--rap-word-btn-h: 45px;
	--rap-word-btn-lh: 45px;
	--rap-word-btn-fs: 20px;
	--rap-word-operate-display: flex;
	--rap-word-operate-ml: 20px;
	--rap-word-operate-p: 12px 15px;
	--rap-word-operate-text-align: center;
	--rap-word-btns-mt: 0;
	--rap-word-img-width: calc(50% + 20px);
	--rap-word-img-justify-content: flex-end;
	--rap-word-main-stay-scroll: scroll;
	--rap-word-main-stay-h: calc(100vh - 130px);
	--rap-star-rate-m: 60px 0 20px 0;
	--rap-title-wrap-fs: 30px;
	--rap-title-catalog-fs: 26px;
}

/* 竖屏样式 */
@media screen and (orientation: portrait) {
    
    /* 适用于手机设备的样式 */
    /* 根据设备宽度、高度、像素密度等进行区分 */
    @media (max-width: 760px) {
        /* 适用于某个手机设备的样式 */
        :root {
			// 页面返回按钮
            --rap-back-btn-W: 40px;

			// 挑战页面按钮尺寸
			--rap-word-h: 50px;
			--rap-word-min-w: 70px;
			--rap-word-gap-x: 10px;
			--rap-word-gap-y: 10px;
			--rap-word-pad-x: 15px;

			// 首页布局
			--rap-menu-card-width: 90%;
			--rap-menu-direction: column;
			--rap-menu-width: 100%;
			--rap-menu-right: 0%;
			--rap-menu-bottom: 20px;
			--rap-menu-h: 60px;
			--rap-menu-card-height: calc(100% - 95px);
			--rap-menu-columns: 2;

			// 单词听写
			--rap-word-statistics-m: 20px 0;
			--rap-progress-timer-mb: 10px;
			--rap-word-operate-m: 30px 0 40px 0;
			--rap-word-btn-w: 60px;
			--rap-word-btn-h: 40px;
			--rap-word-btn-lh: 42px;
			--rap-word-btn-fs: 20px;
			--rap-word-operate-display: inline-block;
			--rap-word-operate-ml: 0;
			--rap-word-operate-p: 12px 15px;
			--rap-word-operate-text-align: center;
			--rap-word-btns-mt: 20px;
			--rap-word-img-width: auto;
			--rap-word-img-justify-content: center;
			--rap-star-rate-m: 50px 0 70px 0;
			--rap-title-wrap-fs: 30px;
			--rap-title-catalog-fs: 26px;
        }
    }
    
    /* 适用于平板设备的样式 */
    @media screen and (min-width: 481px) and (max-width: 1024px) and (min-height: 801px) and (max-height: 1366px) {
        /* 适用于某个平板设备的样式 */
		:root {
			// 页面返回按钮
            --rap-back-btn-W: 40px;

			// 挑战页面按钮尺寸
			--rap-word-h: 65px;
			--rap-word-min-w: 70px;
			--rap-word-gap-x: 15px;
			--rap-word-gap-y: 15px;
			--rap-word-pad-x: 25px;

			// 首页布局
			--rap-menu-direction: row;
			--rap-menu-h: 55px;
			--rap-menu-columns: 3;

			// 单词听写
			--rap-word-statistics-m: 40px 0;
			--rap-progress-timer-mb: 50px;
			--rap-word-operate-m: 50px 0;
			--rap-word-btn-w: 60px;
			--rap-word-btn-h: 45px;
			--rap-word-btn-lh: 45px;
			--rap-word-btn-fs: 20px;
			--rap-word-operate-display: flex;
			--rap-word-operate-ml: 80px;
			--rap-word-operate-p: 12px 15px;
			--rap-word-img-width: calc(50% + 20px);
			--rap-word-img-justify-content: flex-end;
			--rap-star-rate-m: 50px 0 70px 0;
			--rap-title-wrap-fs: 40px;
			--rap-title-catalog-fs: 36px;
        }
    }
}

/* 横屏样式 */
@media screen and (orientation: landscape) {
    /* 适用于手机设备的样式 */
    /* 根据设备宽度、高度、像素密度等进行区分 */
    @media screen and (max-width: 900px) {
        /* 适用于某个手机设备的样式 */
        :root {
			// 页面返回按钮
            --rap-back-btn-W: 50px;

			// 挑战页面按钮尺寸
			--rap-word-h: 55px;
			--rap-word-min-w: 70px;
			--rap-word-gap-x: 10px;
			--rap-word-gap-y: 10px;
			--rap-word-pad-x: 15px;

			--rap-menu-columns: 3;
			--rap-menu-card-height: 100%;

			// 单词听写
			--rap-word-statistics-m: 20px 0;
			--rap-progress-timer-mb: 10px;
			--rap-word-operate-m: 30px 0 40px 0;
			--rap-word-btn-w: 60px;
			--rap-word-btn-h: 45px;
			--rap-word-btn-lh: 45px;
			--rap-word-btn-fs: 20px;
			--rap-word-operate-display: flex;
			--rap-word-operate-ml: 20px;
			--rap-word-operate-p: 12px 15px;
			--rap-word-operate-text-align: center;
			--rap-word-btns-mt: 0;
			--rap-word-img-width: calc(50% + 20px);
			--rap-word-img-justify-content: flex-end;
			--rap-word-main-stay-scroll: scroll;
			--rap-word-main-stay-h: calc(100vh - 130px);
			--rap-star-rate-m: 60px 0 20px 0;
			--rap-title-wrap-fs: 30px;
			--rap-title-catalog-fs: 26px;
        }
    }
    
    /* 适用于平板设备的样式 */
    @media screen and (min-width: 1024px) and (min-height: 768px) {
        /* 适用于某个平板设备的样式 */
		:root {
            --rap-back-btn-W: 40px;

			--rap-word-h: 70px;
			--rap-word-min-w: 70px;
			--rap-word-gap-x: 15px;
			--rap-word-gap-y: 15px;
			--rap-word-pad-x: 25px;

			// 首页布局
			--rap-menu-h: 60px;

			// 单词听写
			--rap-word-statistics-m: 40px 0;
			--rap-progress-timer-mb: 50px;
			--rap-word-operate-m: 50px 0;
			--rap-word-btn-w: 60px;
			--rap-word-btn-h: 45px;
			--rap-word-btn-lh: 45px;
			--rap-word-btn-fs: 20px;
			--rap-word-operate-display: flex;
			--rap-word-operate-ml: 80px;
			--rap-word-operate-p: 12px 15px;
			--rap-word-img-width: calc(50% + 20px);
			--rap-word-img-justify-content: flex-end;
			--rap-star-rate-m: 50px 0 70px 0;
			--rap-title-wrap-fs: 40px;
			--rap-title-catalog-fs: 36px;
        }
    }
}


.slide-right-enter-active, .slide-right-leave-active, .slide-left-enter-active, .slide-left-leave-active {
    will-change: transform;
    transition: all 600ms;
    position: absolute;
}

.slide-right-enter {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-active {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}

.slide-left-leave-active {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}

.vue-lottie-player {
	background-color: transparent !important;
}
</style>
