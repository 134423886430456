import ttsRecorder from "./js/index"

var tts = new ttsRecorder();
export default {
    // options
    // wordId: '',
    // text: 合成词语
    // onend: 播放完成回调
    initTTS (options) {
        if ('speechSynthesis' in window) {
            window.speechSynthesis.cancel();

            const utterance = new SpeechSynthesisUtterance(options.text);
            utterance.voice = (window.speechSynthesis.getVoices() || []).filter(voice => voice.lang.startsWith('en-US'))[0];
            window.speechSynthesis.speak(utterance);

            // 播放完成回调
            utterance.onend = () => {
                if (options.onend instanceof Function) {
                    options.onend();
                } 
            }
        } else {
            tts.setParams({ text: options.text })
            tts.start(options.onend);
            // if (options.wordId) {
            //     downloadPCM(new Int16Array(ttsRecorder.rawAudioData, options.wordId))
            // }
        }
    },

    cancelTTS () {
        if ('speechSynthesis' in window) {
            window.speechSynthesis.cancel();
        } else {
            tts && tts.stop();
        }
    }
}