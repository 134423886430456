<template>
    <div class="play-sound" :class="{'play': play}" :style="style">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-volume"><polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon></svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-volume-1"><polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon><path d="M15.54 8.46a5 5 0 0 1 0 7.07"></path></svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-volume-2"><polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon><path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path></svg>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: Number,
            default: 24,
        },
        color: {
            type: String,
            default: '#FF6E20'
        },
        play: Boolean
    },
    computed: {
        style () {
            return `color: ${this.color}; width: ${this.size}px;height: ${this.size}px;`
        }
    }
}
</script>

<style lang="scss" scoped>
.play-sound {
    color: #000000;
    position: relative;
    margin: 0 10px;
    svg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100% !important;
        height: 100% !important;
    }

    &.play {
        svg {
            &:nth-of-type(2) path{
                animation: fade1 1s linear infinite;
            }
            &:nth-of-type(3) path{
                animation: fade2 1s linear infinite;
            }
        }
    }
}

@keyframes fade1 {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@keyframes fade2 {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

</style>