import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import store from "./store"
import PlaySound from "./components/play-sound.vue"
import PageTitleCard from "./components/page-title-card.vue"
import Speck from "./util/speck"

Vue.use(VueRouter);
Vue.component('play-sound', PlaySound);
Vue.component('page-title-card', PageTitleCard)

// 路由
const router = new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '/',
			meta: {
				index: 1,
				keepAlive: true
			},
			component: () => import('./views/home/index.vue')
		},
		{
			path: '/game/:volumeId/:catalogId',
			meta: {
				index: 2
			},
			component: () => import('./views/game/index.vue')
		},
		{
			path: '/word/:volumeId/:catalogId',
			meta: {
				index: 2
			},
			component: () => import('./views/word/index.vue')
		},
		{
			path: '/dictation/:volumeId/:catalogId',
			meta: {
				index: 2
			},
			component: () => import('./views/dictation/index.vue')
		},
	]
})

// 组件
import BackBtn from "./components/back-btn.vue";

Vue.component('back-btn', BackBtn);

Vue.config.productionTip = false
Vue.prototype.$speck = Speck;

new Vue({
	store,
	router,
	render: h => h(App)
}).$mount('#app')
