<template>
    <div class="page-title-card">
        <span class="title">{{ subjectName }}</span> - <span>{{ getUnitName.toUpperCase() }}</span>
    </div>
</template>

<script>
export default {
    computed: {
        // 路由参数
        routeParams () {
            return this.$route.params;
        },

        // 课程名称
        subjectName () {
            return this.$store.getters.getSubjectName(this.routeParams.volumeId);
        },
        // 单元名称
        getUnitName () {
            return this.$store.getters.getUnitName(this.routeParams.volumeId, this.routeParams.catalogId);
        },
    },
}
</script>

<style lang="scss" scoped>
.page-title-card {
	color: #FF6E20;
    font-size: 22px;
    font-weight: bold;
    padding-top: 10px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: baseline;
    .title {
        font-size: 24px;
    }
}
</style>