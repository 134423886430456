<template>
    <div class="back-btn" @click="$router.back(-1)"></div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

.back-btn {
    position: fixed;
    top: 20px;
    left: 20px;
    width: var(--rap-back-btn-W);
    height: var(--rap-back-btn-W);
    background: linear-gradient(to right, rgba(228, 52, 11, .81), rgba(234, 91, 59 ,1), rgba(244, 158, 137, .8));
    border-radius: 50%;
    box-shadow: 0 2px 12px 0 rgba(228, 52, 11, .4);
    z-index: 8;

    &::before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: url('../assets/backward.svg') center no-repeat;
        background-size: 60%;
    }

    &:active {
        opacity: 0.8;
        transition: all .3s ease-in-out;
    }
}
</style>