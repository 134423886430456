
import { nanoid } from 'nanoid';

// 归类数据
// list数据; key对象归类的参考值
export const classifyListItem = (lists, key) => {
    const datas = [];
    (lists || []).map(p => {
        if (p[key]) {
            if (!datas.find(q => q[key] == p[key])) {
                datas.push({
                    id: nanoid(10),
                    [key]: p[key],
                    children: [p]
                })
            } else {
                const index = datas.findIndex(q => q[key] == p[key]);
                datas[index].children.push(p);
            }
        }
    })
    return datas;
}