import Vue from 'vue'
import Vuex from 'vuex'
import * as XLSX from "xlsx"
import axios from 'axios'
import { classifyListItem } from "./utils"
import { nanoid } from 'nanoid';

Vue.use(Vuex)

const subject = localStorage.getItem('RAP-SUBJECT') ? JSON.parse(localStorage.getItem('RAP-SUBJECT')) : [];
const store = new Vuex.Store({
    state: {
        subject, // 课程
        colors: [],
        curSubjectId: localStorage.getItem('RAP-CUR-SUBJECTID') || '', // 当前课程
    },
    getters: {
        // 获取课程
        subject: (state) => {
            return state.subject || [];
        },

        // 获取课程
        getSubjectList: (state) => {
            return state.subject || [];
        },

        // 获取课程名称
        getSubjectName: (state) => (id) => {
            const data = (state.subject || []).find(p => p.id == id || p.id == state.curSubjectId);
            return data ? data.volume : '';
        },

        // 获取课程单元列表
        getUnitList: (state) => (id) => {
            const data = (state.subject || []).find(p => p.id == id || p.id == state.curSubjectId);
            return data ? data.children : [];
        },

        // 获取单元名称
        getUnitName: (state, getters) => (subjectId, unitId) => {
            const unitList = getters.getUnitList(subjectId) || [];
            const data = (unitList || []).find(p => p.id == unitId);
            return data ? data.catalog : '';
        },

        // 获取单元对象
        getUnitItem: (state, getters) => (subjectId, unitId) => {
            const unitList = getters.getUnitList(subjectId) || [];
            const data = (unitList || []).find(p => p.id == unitId);
            return data;
        },

        // 获取单元词汇列表
        getWordList: (state, getters) => (subjectId, unitId) => {
            const unitList = getters.getUnitList(subjectId) || [];
            const data = (unitList || []).find(p => p.id == unitId);
            return data ? data.children : [];
        },

        // 获取单元词汇乱序
        getRandomWordList: (state, getters) => (subjectId, unitId) => {
            const wordList = getters.getWordList(subjectId, unitId) || [];
            return wordList.sort((a, b) => { return Math.random() > 0.5 ? -1 : 1 });
        },

        // 获取单元词汇(中英文)列表
        getWordsList: (state, getters) => (subjectId, unitId) => {
            const unitList = getters.getUnitList(subjectId) || [];
            const data = (unitList || []).find(p => p.id == unitId);
            const words = [];
            (data.children || []).map(p => {
                words.push(p.wordEn);
                words.push(p.wordCh);
            })
            return words;
        },

        // 获取单元词汇(中英文)随机
        getRandomWordsList: (state, getters) => (subjectId, unitId) => {
            const wordsList = getters.getWordsList(subjectId, unitId) || [];
            return wordsList.sort((a, b) => { return Math.random() > 0.5 ? -1 : 1 });
        }
    },

    mutations: {
        // 设置课程
        SET_SUBJECT: (state, data) => {
            state.subject = data;
            localStorage.setItem('RAP-SUBJECT', JSON.stringify(data));
        },

        // 设置当前第几册
        SET_CURRENT_SUBJECT: (state, data) => {
            state.curSubjectId = data;
            localStorage.setItem('RAP-CUR-SUBJECTID', data);
        },

        // 保存单元信息
        SET_UNIT_RECORD: (state, data) => {

        }
    },

    actions: {
        // 获取数据
        getSubjectData ({ commit }) {
            axios.get('/dataSource.xlsx', {
                responseType: 'arraybuffer', // 设置响应体类型arraybuffer
            }).then(res => {
                if (res.status == 200) {
                    if (localStorage.getItem('byteLength') == res.data.byteLength) {
                        return
                    }
                    localStorage.setItem('byteLength', res.data.byteLength);
                    let workbook = XLSX.read(new Uint8Array(res.data), { type: 'array' }) // 解析数据
                    let worksheet = workbook.Sheets[workbook.SheetNames[0]] // workbook.SheetNames 下存的是该文件每个工作表名字,这里取出第一个工作表
                    var outdata = XLSX.utils.sheet_to_json(worksheet) // 渲染
                    // 序号: serial;
                    // 第几册: volume;
                    // 目录: catalog;
                    // 课时: class;
                    // 类型: type; 
                    // 英文: wordEn; 
                    // 中文: wordCh
                    var keys = [
                        { key: 'serial', value: '序号' },
                        { key: 'volume', value: '第几册' },
                        { key: 'catalog', value: '目录' },
                        { key: 'class', value: '课时' },
                        { key: 'type', value: '类型' },
                        { key: 'wordEn', value: '英文' },
                        { key: 'wordCh', value: '中文' }
                    ];

                    // 转对象数组
                    const dataList = [];
                    (outdata || []).map(p => {
                        const item = {
                            id: nanoid(10),
                        };
                        keys.map(t => { item[t.key] = p[t.value] || ''; })
                        dataList.push(item);
                    })

                    // 归类
                    const firstData = classifyListItem(dataList, 'volume'), subjectData = [];
                    firstData.map(p => {
                        subjectData.push({
                            ...p,
                            children: classifyListItem(p.children, 'catalog')
                        })
                    })

                    // console.log('subjectData:', subjectData)
                    commit('SET_SUBJECT', subjectData || []);
                    commit('SET_CURRENT_SUBJECT', subjectData.length ? subjectData[0].id : '');
                }
            })
        },

        // 处理保存单元连续挑战|单元听写记录
        saveUnitRecord ({ commit, state, getters }, data) {
            return new Promise(resolve => {
                const volume = (state.subject || []).find(p => p.id == data.volumeId);
                if (volume) {
                    const catalog = (volume.children || []).find(p => p.id == data.catalogId);
                    if (data.dictation) {
                        catalog.dictation = data.dictation;
                    } else if (data.challenge) {
                        catalog.challenge = data.challenge;
                    }
                    commit('SET_SUBJECT', state.subject);
                }
                resolve();
            })
        }
    }
})

export default store